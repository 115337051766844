<template>
  <div id="app">
    <div id="page">
      <header class="header menu_fixed">
        <div id="preloader"><div data-loader="circle-side"></div></div>
        <!-- /Page Preload -->
        <div id="logo">
          <a href="/">
            <img
              src="./assets/img/logo.svg"
              width="150"
              height="auto"
              alt="Ada Bojana For Me"
              class="logo_normal"
            />
            <img
              src="./assets/img/logo_sticky.svg"
              width="auto"
              height="50px"
              alt="Ada Bojana For Me"
              class="logo_sticky"
            />
          </a>
        </div>

        <!--        <ul id="top_menu">-->
        <!--          <li>-->
        <!--            <a href="#sign-in-dialog" id="sign-in" class="login" title="Sign In"-->
        <!--              >Sign In</a-->
        <!--            >-->
        <!--          </li>-->
        <!--        </ul>-->
        <!-- /top_menu -->

        <a href="#menu" class="btn_mobile">
          <div class="hamburger hamburger--spin" id="hamburger">
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div>
        </a>
        <nav id="menu" class="main-menu">
          <ul>
            <li>
              <span><a href="/">Home</a></span>
            </li>
            <li>
              <span
                ><a href="javascript:">{{ $t('menu.accommodation') }}</a></span
              >
              <ul>
                <li v-for="(type, index) in propertyTypes" :key="index">
                  <a :href="type.url">{{ type.title }}</a>
                </li>
              </ul>
            </li>
            <li>
              <span
                ><a href="javascript:">{{ $t('menu.locations') }}</a></span
              >
              <ul>
                <li v-for="(city, index) in cities" :key="index">
                  <a :href="'/city/' + city.slug">{{ city.title }}</a>
                </li>
              </ul>
            </li>
            <li>
              <span
                ><a href="/contact">{{ $t('menu.contact') }}</a></span
              >
            </li>
          </ul>
        </nav>
      </header>
      <!-- /header -->
      <router-view />
      <footer>
        <div class="container margin_60_35">
          <div class="row">
            <div class="col-lg-5 col-md-12 p-r-5">
              <p>
                <img
                  src="./assets/img/logo.svg"
                  width="150"
                  height="auto"
                  alt="Ada Bojana For Me"
                />
              </p>
              <p>
                Ukoliko ste ljubitelj netaknute prirode, volite kite surf i
                skijanje na vodi, želite da osetite autentičnost prirode na reci
                Bojani i tražite iskonsku avanturu i drugačiji odmor, odaberite
                sojenice na reci, kućice, apartmane ili kampove.
              </p>
              <div class="follow_us">
                <ul>
                  <li>{{ $t('footer.follow_us') }}</li>
                  <li>
                    <a
                      href="https://sr-rs.facebook.com/adabojanaforme"
                      target="_blank"
                      rel="noopener"
                      ><i class="ti-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/adabojanaforme"
                      target="_blank"
                      rel="noopener"
                      ><i class="ti-instagram"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 ml-lg-auto">
              <h5>Sitemap</h5>
              <ul class="links">
                <li><a href="/">Home</a></li>
                <li>
                  <a href="/smestaj-ulcinj-ada-bojana">{{
                    $t('menu.accommodation')
                  }}</a>
                </li>
                <li><a href="/city/bojana">Ada Bojana</a></li>
                <li>
                  <a href="/contact">{{ $t('menu.contact') }}</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6">
              <h5>{{ $t('menu.contact') }}</h5>
              <ul class="contacts">
                <li>
                  <a href="tel://+381644295529"
                    ><i class="ti-mobile"></i> +381 64 4295 529</a
                  >
                </li>
                <li>
                  <a href="tel://+381641111077"
                    ><i class="ti-mobile"></i> +381 64 1111 077</a
                  >
                </li>
                <li>
                  <a href="mailto:info@adabojanafor.me"
                    ><i class="ti-email"></i> info@adabojanafor.me</a
                  >
                </li>
              </ul>
              <div id="newsletter">
                <h6>Newsletter</h6>
                <div id="message-newsletter"></div>
                <form
                  @submit.prevent="submit"
                  name="newsletter_form"
                  id="newsletter_form"
                >
                  <div class="form-group">
                    <input
                      v-model.trim="$v.email.$model"
                      type="email"
                      name="email_newsletter"
                      id="email_newsletter"
                      class="form-control"
                      placeholder="Email"
                    />
                    <div
                      v-if="$v.email.$error"
                      class="invalid-feedback"
                      style="display: block"
                    >
                      {{ $t('validation.email_missing') }}
                    </div>
                    <input
                      type="submit"
                      :value="$t('footer.subscribe')"
                      id="submit-newsletter"
                    />
                  </div>
                </form>

                <div
                  v-if="subscriptionSubmitted"
                  class="alert alert-success"
                  role="alert"
                >
                  {{ $t('validation.newsletter_subscription_success') }}
                </div>

                <div
                  v-if="subscriptionError"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ $t('validation.newsletter_subscription_error') }}
                </div>
              </div>
            </div>
          </div>
          <!--/row-->
          <hr />
          <div class="row">
            <div class="col-lg-6">
              <ul id="footer-selector">
                <li>
                  <div class="styled-select" id="lang-selector">
                    <select
                      v-model="selectedLanguage"
                      @change="switchLanguage($event)"
                    >
                      <option
                        v-for="(item, i) in languages"
                        :key="i"
                        :value="item.lang"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul id="additional_links">
                <li>
                  <a href="javascript:">{{ $t('copyright.terms') }}</a>
                </li>
                <li>
                  <a href="javascript:">{{ $t('copyright.privacy') }}</a>
                </li>
                <li>
                  <span
                    >© {{ new Date().getFullYear() }} Stefanovic Kolektiv</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <!--/footer-->
    </div>

    <!-- Sign In Popup -->
    <sign-in-popup></sign-in-popup>
    <!-- /Sign In Popup -->

    <div id="toTop"></div>
    <!-- Back to top button -->
  </div>
</template>

<script>
import SignInPopup from '@/components/SignInPopup'
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import i18nService from '@/services/i18n.service.js'

export default {
  name: 'App',
  components: { SignInPopup },
  metaInfo() {
    return {
      title: 'Smeštaj Ulcinj Ada Bojana',
      titleTemplate: '%s | Ada Bojana For Me',
      meta: [
        {
          name: 'description',
          content:
            'Ukoliko ste ljubitelj netaknute prirode, volite kite surf, prelepe zalaske sunca, želite da osetite autentičnost prirode na reci Bojani i tražite iskonsku avanturu i drugačiji odmor, odaberite sojenice na reci, kućice, apartmane ili kampove.',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: process.env.VUE_APP_BASE_URL + this.$route.path,
        },
        {
          property: 'og:title',
          content: 'Smeštaj Ulcinj Ada Bojana | Ada Bojana For Me',
        },
        {
          property: 'og:description',
          content:
            'Ukoliko ste ljubitelj netaknute prirode, volite kite surf, prelepe zalaske sunca, želite da osetite autentičnost prirode na reci Bojani i tražite iskonsku avanturu i drugačiji odmor, odaberite sojenice na reci, kućice, apartmane ili kampove.',
        },
        {
          property: 'og:image',
          content:
            'https://api.adabojanafor.me/storage/covers/city/ada-bojana.jpg',
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: process.env.VUE_APP_BASE_URL + this.$route.path,
        },
        {
          property: 'twitter:title',
          content: 'Smeštaj Ulcinj Ada Bojana | Ada Bojana For Me',
        },
        {
          property: 'twitter:description',
          content:
            'Ukoliko ste ljubitelj netaknute prirode, volite kite surf, prelepe zalaske sunca, želite da osetite autentičnost prirode na reci Bojani i tražite iskonsku avanturu i drugačiji odmor, odaberite sojenice na reci, kućice, apartmane ili kampove.',
        },
        {
          property: 'twitter:image',
          content:
            'https://api.adabojanafor.me/storage/covers/city/ada-bojana.jpg',
        },
        { name: 'robots', content: 'index,follow' },
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.VUE_APP_BASE_URL + this.$route.path,
        },
      ],
    }
  },
  data: function () {
    return {
      propertyTypes: [
        {
          title: this.$i18n.t('propertyTypes.all'),
          url: '/smestaj-ulcinj-ada-bojana',
        },
        { title: this.$i18n.t('propertyTypes.hotels'), url: '/listings/hotel' },
        {
          title: this.$i18n.t('propertyTypes.river_houses'),
          url: '/listings/sojenica',
        },
        {
          title: this.$i18n.t('propertyTypes.apartments'),
          url: '/listings/apartman',
        },
        { title: this.$i18n.t('propertyTypes.camps'), url: '/listings/kamp' },
      ],
      email: '',
      subscriptionSubmitted: false,
      subscriptionError: false,
      languages: i18nService.languages,
      selectedLanguage: i18nService.getActiveLanguage(),
    }
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const formData = require('form-data')
        const Mailgun = require('mailgun.js')
        const mailgun = new Mailgun(formData)
        const mg = mailgun.client({
          username: 'api',
          key: process.env.VUE_APP_MAILGUN_API_KEY || '',
        })
        mg.messages
          .create('mg.stefanovic-kolektiv.com', {
            from: 'Stefanovic Kolektiv Newsletter Service <no-reply@stefanovic-kolektiv.com>',
            to: ['info@adabojanafor.me'],
            subject: `${this.email} newsletter subscription`,
            html: `Korisnik ${this.email} se prijavio za newsletter listu.`,
          })
          .then(
            () => (this.subscriptionSubmitted = !this.subscriptionSubmitted)
          )
          .catch(() => (this.subscriptionError = !this.subscriptionError))
      }
    },
    switchLanguage(event) {
      i18nService.setActiveLanguage(event.target.value)
      window.location.reload()
    },
  },
  computed: {
    ...mapGetters(['cities']),
  },
}
</script>

<style lang="scss">
//GOOGLE WEB FONT
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import 'assets/css/bootstrap.css';
@import 'assets/css/style.css';
@import 'assets/css/vendors.css';
@import 'assets/css/kolektiv.css';
</style>
